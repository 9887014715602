import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  ControlButton,
  // useEdgesState,
} from "react-flow-renderer";
import "./styles.css";
import InitialElements from "./initialElements";
import { useScreenshot } from "use-react-screenshot";
// import { SmartStepEdge } from "@tisoap/react-flow-smart-edge";
import { stringify } from "flatted";
import Sidebar from "./sidebar.js";
import {
  MdAdd,
  MdCallMerge,
  MdCallSplit,
  MdDelete,
  MdLibraryAdd,
  MdLogin,
  MdMessage,
  MdOutlineCheck,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineLayers,
} from "react-icons/md";
import SmartEdge from "@tisoap/react-flow-smart-edge";
let id = 0;
const getId = () => `dndnode_${id++}`;

export default function App() {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [hideSide, setHideSide] = useState(true);
  const [jsonHide, setHideJsonHide] = useState(true);
  const edgeTypes = {
    Entity: SmartEdge,
    Message: SmartEdge,
    Confirmation: SmartEdge,
  };

  const [dataObj, setDataObj] = useState({
    label: "",
    desc: "",
    id: "",
    icon: "",
  });
  const [elements, setElements] = useState(InitialElements);
  const [edges, setEdges] = useState([]);

  const onConnect = (params) => {
    setElements((els) => addEdge({ ...params, type: "Message" }, els));
    // setEdges((els) => addEdge({ ...params, type: "Message" }, els));
  };

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode = {
      id: getId(),

      position,
      data: {
        label:
          type == "input" ? (
            <div
              style={{
                backgroundColor: "blue",
                borderRadius: 100,
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transform: "rotate(180deg)",
                transition: "transform 150ms ease",
                marginLeft: 50,
              }}
            >
              <MdCallSplit size={30} color="#fff" />
            </div>
          ) : type == "output" ? (
            <div
              style={{
                backgroundColor: "blue",
                borderRadius: 100,
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                transform: "rotate(180deg)",
                transition: "transform 150ms ease",
                marginLeft: 50,
              }}
            >
              <MdCallMerge size={30} color="#fff" />
            </div>
          ) : (
            <div
              id={getId()}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: -10,
                // justifyContent: "center",
              }}
              onClick={(e) => {
                // Get the child element by its ID
                const childElement = e.currentTarget;

                // Get the parent element
                const parentElement = childElement?.parentElement;

                // Get the attribute from the parent element
                const parentAttribute = parentElement.getAttribute("data-id");
                console.log("parentAttribute", parentAttribute);
                setDataObj({
                  label: type,
                  desc: "Description",
                  id: parentAttribute, //elements[elements?.length - 1]?.id,
                  icon:
                    type === "User action" ? (
                      <MdLogin
                        color="#000"
                        size={30}
                        style={{ marginRight: 20 }}
                      />
                    ) : type === "Entity" ? (
                      <MdOutlineLayers
                        size={30}
                        color="#000"
                        style={{ marginRight: 20 }}
                      />
                    ) : type === "Message" ? (
                      <MdMessage
                        size={30}
                        color="#000"
                        style={{ marginRight: 20 }}
                      />
                    ) : type === "Confirmation" ? (
                      <MdOutlineCheck
                        size={30}
                        color="#000"
                        style={{ marginRight: 20 }}
                      />
                    ) : null,
                });
              }}
            >
              {type === "User action" ? (
                <MdLogin color="#000" size={30} style={{ marginRight: 20 }} />
              ) : type === "Entity" ? (
                <MdOutlineLayers
                  size={30}
                  color="#000"
                  style={{ marginRight: 20 }}
                />
              ) : type === "Message" ? (
                <MdMessage size={30} color="#000" style={{ marginRight: 20 }} />
              ) : type === "Confirmation" ? (
                <MdOutlineCheck
                  size={30}
                  color="#000"
                  style={{ marginRight: 20 }}
                />
              ) : null}
              <div>
                <p className="lable" style={{ fontSize: 12 }}>
                  {type}
                </p>
                <p className="lable" style={{ fontSize: 10 }}>
                  Description Description
                </p>
              </div>
            </div>
          ),
      },
      style: {
        width: type == "input" || type == "output" ? 150 : 250,
        height: 50,
        border: type == "input" || type == "output" ? 0 : "1px solid lightgray",
      },
    };
    setElements((es) => es.concat(newNode));
  };

  const modityJsonPbj = (id, label, desc) => {
    return {
      id: id,
      data: {
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: -10,
              // justifyContent: "center",
            }}
          >
            <div>
              <p className="lable" style={{ fontSize: 12 }}>
                {label}
              </p>
              <p className="lable" style={{ fontSize: 10 }}>
                {desc}
              </p>
            </div>
          </div>
        ),
      },
      position: { x: 100, y: 100 },
      style: {
        width: 250,
        height: 50,
        border: "1px solid lightgray",
      },
    };
  };

  // useEffect(() => {
  //   console.log("................dataObj", dataObj, elements);

  //   setElements((nds) =>
  //     nds?.map((node) => {
  //       if (node.id === dataObj?.id) {
  //         // it's important that you create a new object here
  //         // in order to notify react flow about the change
  //         node.data = {
  //           ...node?.data,
  //           label: `${(
  //             <div
  //               style={{
  //                 display: "flex",
  //                 alignItems: "center",
  //                 marginTop: -10,
  //                 // justifyContent: "center",
  //               }}
  //             >
  //               <img
  //                 src="https://cdn-icons-png.flaticon.com/512/152/152532.png"
  //                 style={{ width: 20, height: 20, marginRight: 20 }}
  //                 alt="icon"
  //               />
  //               <div>
  //                 <p className="lable" style={{ fontSize: 12 }}>
  //                   ${dataObj?.label}
  //                 </p>
  //                 <p className="lable" style={{ fontSize: 10 }}>
  //                   ${dataObj?.desc}
  //                 </p>
  //               </div>
  //             </div>
  //           )}`,
  //         };
  //       }

  //       return { ...node, label: JSON.stringify(node.data.label, null, 2) };
  //     })
  //   );

  //   // Example logging with flatted
  //   // console.log(stringify(dataObj));
  // }, [dataObj]);

  console.log("element", elements);

  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(ref.current);

  return (
    <div className="dndflow" ref={ref}>
      <div className="leftLine">
        <div
          onClick={() => setHideSide(!hideSide)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
            cursor: "pointer",
            zIndex: 9999,
          }}
        >
          <MdLibraryAdd size={24} color="#fff" />
        </div>
        <div
          onClick={() => setHideJsonHide(!jsonHide)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
            cursor: "pointer",
            zIndex: 9999,
          }}
        >
          <MdOutlineKeyboardArrowLeft size={24} color="#fff" />
          <MdOutlineKeyboardArrowRight
            size={24}
            color="#fff"
            style={{ marginLeft: -10 }}
          />
        </div>
      </div>
      {hideSide && <Sidebar isLeft={true} />}
      <ReactFlowProvider>
        <div
          className="reactflow-wrapper"
          style={{
            height: !jsonHide ? "900px" : "650px",
            width: "100%",
            marginLeft: !hideSide ? 55 : null,
          }}
          ref={reactFlowWrapper}
        >
          <ReactFlow
            elements={elements}
            onElementClick={(e) => {
              const childElement = e?.target?.dataset?.id;

              // const parentElement = childElement?.parentElement;

              // const parentAttribute = parentElement.getAttribute("data-id");
              console.log("e.currentTarget..", childElement);
              setDataObj({ ...dataObj, id: childElement });
            }}
            // nodes={nodes}
            // edges={edges}
            // onNodesChange={onNodesChange}
            // onEdgesChange={onEdgesChange}

            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            // contentEditable
            // snapToGrid={true}
            edgeTypes={edgeTypes}
            onLoad={onLoad}
            onDrop={onDrop}
            onDragOver={onDragOver}
          >
            <Controls>
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFnwDq06rOFy1SPK0sryNQtFeXTH8KyMkWcw&s"
                style={{ width: 15, height: 15, marginLeft: 7, marginTop: 6 }}
                onClick={getImage}
              />
            </Controls>
          </ReactFlow>

          {jsonHide && (
            <div style={{ height: "100px", width: "100%" }}>
              <pre class="custom-pre">{JSON.stringify(elements, null, 2)}</pre>
            </div>
          )}
        </div>

        <aside>
          <div className="description"></div>
          <h2 className="comp">Components</h2>
          <div
            style={{
              border: 0,
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "space-between",
            }}
          >
            <p
              className="lable"
              style={{ color: !dataObj?.id ? "lightgray" : "#000" }}
            >
              Label
            </p>
            <input
              className="input"
              placeholder="Label"
              defaultValue={dataObj?.label}
              onChange={(e) =>
                setDataObj({ ...dataObj, label: e.target.value })
              }
              disabled={!dataObj?.id}
            />
          </div>
          {dataObj?.id && (
            <>
              <div
                style={{
                  border: 0,
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                <p className="lable">Description</p>
                <input
                  className="input"
                  placeholder="Description"
                  defaultValue={dataObj?.desc}
                  onChange={(e) =>
                    setDataObj({ ...dataObj, desc: e.target.value })
                  }
                />
              </div>
              <div
                style={{
                  border: 0,
                }}
              >
                <p className="lable">Icon</p>
                <input
                  className="input"
                  placeholder="Icon"
                  defaultValue={dataObj?.icon}
                />
              </div>

              <div className="outDiv">
                <p className="outP">Out Ports</p>
                <div className="iconP">
                  <MdAdd size={20} color="#fff" />
                </div>
              </div>
            </>
          )}
        </aside>
      </ReactFlowProvider>
    </div>
  );
}
