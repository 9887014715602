import React from "react";
import {
  MdCallSplit,
  MdCallMerge,
  MdLogin,
  MdOutlineLayers,
  MdMessage,
  MdOutlineCheck,
  MdLibraryAdd,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

export default ({ isLeft }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  return (
    <div
      style={{
        padding: 30,
        borderRight: "1px solid lightgray",
        height: "870px",
      }}
    >
      <div
        className="dndnode input"
        onDragStart={(event) => onDragStart(event, "input")}
        draggable
        style={{
          border: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <div
          style={{
            backgroundColor: "blue",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            transform: "rotate(180deg)",
            transition: "transform 150ms ease",
          }}
        >
          <MdCallSplit size={30} color="#fff" />
        </div>
        <div>
          <p className="lable" style={{ marginRight: 30 }}>
            Start
          </p>
        </div>
        <p className="lable"></p>
      </div>
      <div
        className="dndnode output"
        onDragStart={(event) => onDragStart(event, "output")}
        draggable
        style={{
          border: 0,
          marginTop: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <div
          style={{
            backgroundColor: "blue",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            transform: "rotate(180deg)",
            transition: "transform 150ms ease",
          }}
        >
          <MdCallMerge size={30} color="#fff" />
        </div>
        <p className="lable" style={{ marginRight: 30 }}>
          End
        </p>
        <p className="lable"></p>
      </div>
      <div
        className="dndnode"
        onDragStart={(event) => onDragStart(event, "User action")}
        draggable
        style={{
          border: 0,
          marginTop: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            border: "1px solid lightgray",
            // transform: "rotate(180deg)",
            // transition: "transform 150ms ease",
          }}
        >
          <MdLogin size={30} color="#000" />
        </div>
        <p className="lable" style={{ marginRight: 8 }}>
          User action
        </p>
      </div>
      <div
        className="dndnode"
        onDragStart={(event) => onDragStart(event, "Entity")}
        draggable
        style={{
          border: 0,
          marginTop: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            border: "1px solid lightgray",
            // transform: "rotate(180deg)",
            // transition: "transform 150ms ease",
          }}
        >
          <MdOutlineLayers size={30} color="#000" />
        </div>
        <p className="lable" style={{ marginRight: 20 }}>
          Entity
        </p>
        <p className="lable"></p>
      </div>
      <div
        className="dndnode"
        onDragStart={(event) => onDragStart(event, "Message")}
        draggable
        style={{
          border: 0,
          marginTop: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            border: "1px solid lightgray",
            // transform: "rotate(180deg)",
            // transition: "transform 150ms ease",
          }}
        >
          <MdMessage size={25} color="#000" />
        </div>
        <p className="lable" style={{ marginRight: 20 }}>
          Message
        </p>
      </div>
      <div
        className="dndnode"
        onDragStart={(event) => onDragStart(event, "Confirmation")}
        draggable
        style={{
          border: 0,
          marginTop: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 50,
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            border: "1px solid lightgray",
            // transform: "rotate(180deg)",
            // transition: "transform 150ms ease",
          }}
        >
          <MdOutlineCheck size={30} color="#000" />
        </div>
        <p className="lable" style={{ marginLeft: 15 }}>
          Confirmation
        </p>
      </div>
    </div>
  );
};
