import { MdCallMerge, MdCallSplit } from "react-icons/md";

export default [
  {
    id: "1",
    type: "input",
    data: {
      label: (
        <div
          style={{
            backgroundColor: "blue",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "rotate(180deg)",
            transition: "transform 150ms ease",
            marginLeft: 50,
          }}
        >
          <MdCallSplit size={30} color="#fff" />
        </div>
      ),
    },
    position: { x: 250, y: 0 },
    style: {
      border: 0,
    },
  },
  // {
  //   id: "2",

  //   data: {
  //     label: (
  // <div
  //   style={{
  //     display: "flex",
  //     alignItems: "center",
  //     marginTop: -10,
  //     // justifyContent: "center",
  //   }}
  // >
  //   <img
  //     src="https://cdn-icons-png.flaticon.com/512/152/152532.png"
  //     style={{ width: 20, height: 20, marginRight: 20 }}
  //     alt="icon"
  //   />
  //   <div>
  //     <p className="lable" style={{ fontSize: 12 }}>
  //       Label
  //     </p>
  //     <p className="lable" style={{ fontSize: 10 }}>
  //       Description Description
  //     </p>
  //   </div>
  // </div>
  //     ),
  //   },
  //   position: { x: 100, y: 100 },
  //   style: {
  //     width: 250,
  //     height: 50,
  //     border: "1px solid lightgray",
  //   },
  // },
  {
    id: "6",
    type: "output",
    data: {
      label: (
        <div
          style={{
            backgroundColor: "blue",
            borderRadius: 100,
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            transform: "rotate(180deg)",
            transition: "transform 150ms ease",
            marginLeft: 50,
          }}
        >
          <MdCallMerge size={30} color="#fff" />
        </div>
      ),
    },
    position: { x: 100, y: 480 },
    style: {
      border: 0,
    },
  },
];
